/**
 * @fileoverview gRPC-Web generated client stub for chart
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: ChartService.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.chart = require('./ChartService_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.chart.CandlestickServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.chart.CandlestickServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chart.CandlestickRequest,
 *   !proto.chart.CandlestickResponse>}
 */
const methodDescriptor_CandlestickService_GetCandlestickData = new grpc.web.MethodDescriptor(
  '/chart.CandlestickService/GetCandlestickData',
  grpc.web.MethodType.UNARY,
  proto.chart.CandlestickRequest,
  proto.chart.CandlestickResponse,
  /**
   * @param {!proto.chart.CandlestickRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chart.CandlestickResponse.deserializeBinary
);


/**
 * @param {!proto.chart.CandlestickRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.chart.CandlestickResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chart.CandlestickResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chart.CandlestickServiceClient.prototype.getCandlestickData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chart.CandlestickService/GetCandlestickData',
      request,
      metadata || {},
      methodDescriptor_CandlestickService_GetCandlestickData,
      callback);
};


/**
 * @param {!proto.chart.CandlestickRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chart.CandlestickResponse>}
 *     Promise that resolves to the response
 */
proto.chart.CandlestickServicePromiseClient.prototype.getCandlestickData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chart.CandlestickService/GetCandlestickData',
      request,
      metadata || {},
      methodDescriptor_CandlestickService_GetCandlestickData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chart.SymbolRequest,
 *   !proto.chart.SymbolResponse>}
 */
const methodDescriptor_CandlestickService_GetSymbols = new grpc.web.MethodDescriptor(
  '/chart.CandlestickService/GetSymbols',
  grpc.web.MethodType.UNARY,
  proto.chart.SymbolRequest,
  proto.chart.SymbolResponse,
  /**
   * @param {!proto.chart.SymbolRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chart.SymbolResponse.deserializeBinary
);


/**
 * @param {!proto.chart.SymbolRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.chart.SymbolResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chart.SymbolResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chart.CandlestickServiceClient.prototype.getSymbols =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chart.CandlestickService/GetSymbols',
      request,
      metadata || {},
      methodDescriptor_CandlestickService_GetSymbols,
      callback);
};


/**
 * @param {!proto.chart.SymbolRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chart.SymbolResponse>}
 *     Promise that resolves to the response
 */
proto.chart.CandlestickServicePromiseClient.prototype.getSymbols =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chart.CandlestickService/GetSymbols',
      request,
      metadata || {},
      methodDescriptor_CandlestickService_GetSymbols);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chart.SubscribeRequest,
 *   !proto.chart.RealtimePriceResponse>}
 */
const methodDescriptor_CandlestickService_GetSubscribe = new grpc.web.MethodDescriptor(
  '/chart.CandlestickService/GetSubscribe',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.chart.SubscribeRequest,
  proto.chart.RealtimePriceResponse,
  /**
   * @param {!proto.chart.SubscribeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chart.RealtimePriceResponse.deserializeBinary
);


/**
 * @param {!proto.chart.SubscribeRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.chart.RealtimePriceResponse>}
 *     The XHR Node Readable Stream
 */
proto.chart.CandlestickServiceClient.prototype.getSubscribe =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/chart.CandlestickService/GetSubscribe',
      request,
      metadata || {},
      methodDescriptor_CandlestickService_GetSubscribe);
};


/**
 * @param {!proto.chart.SubscribeRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.chart.RealtimePriceResponse>}
 *     The XHR Node Readable Stream
 */
proto.chart.CandlestickServicePromiseClient.prototype.getSubscribe =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/chart.CandlestickService/GetSubscribe',
      request,
      metadata || {},
      methodDescriptor_CandlestickService_GetSubscribe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.chart.UnsubscribeRequest,
 *   !proto.chart.UnsubscribeResponse>}
 */
const methodDescriptor_CandlestickService_GetUnsubscribe = new grpc.web.MethodDescriptor(
  '/chart.CandlestickService/GetUnsubscribe',
  grpc.web.MethodType.UNARY,
  proto.chart.UnsubscribeRequest,
  proto.chart.UnsubscribeResponse,
  /**
   * @param {!proto.chart.UnsubscribeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.chart.UnsubscribeResponse.deserializeBinary
);


/**
 * @param {!proto.chart.UnsubscribeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.chart.UnsubscribeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.chart.UnsubscribeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.chart.CandlestickServiceClient.prototype.getUnsubscribe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/chart.CandlestickService/GetUnsubscribe',
      request,
      metadata || {},
      methodDescriptor_CandlestickService_GetUnsubscribe,
      callback);
};


/**
 * @param {!proto.chart.UnsubscribeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.chart.UnsubscribeResponse>}
 *     Promise that resolves to the response
 */
proto.chart.CandlestickServicePromiseClient.prototype.getUnsubscribe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/chart.CandlestickService/GetUnsubscribe',
      request,
      metadata || {},
      methodDescriptor_CandlestickService_GetUnsubscribe);
};


module.exports = proto.chart;

