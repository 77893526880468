import { calculateTickPrice } from './helpers.js';
import { client, symbolAllList } from './streaming.js';
import { v4 as uuidv4 } from 'uuid';

const myUUID = uuidv4();
const lastBarsCache = new Map();

const configurationData = {
	supported_resolutions: ['1', '3', '5', '10', '15', '30', '45', '60', '120', '180'],

	// 사용자가 교환을 선택하는 경우 `exchanges` 인수는 `searchSymbols` 메서드에 사용됩니다.
	exchanges: [{
		value: '거래가능',
		name: '거래가능',
		desc: '거래가능',
	},
	],
	// 사용자가 이 심볼 유형을 선택하면 `symbols_types` 인수가 `searchSymbols` 메서드에 사용됩니다.
	symbols_types: [
	],

};

export default {
	onReady: (callback) => {
		console.log('[onReady]: Method call');
		setTimeout(() => callback(configurationData));
		console.log('[onReady]: Method end');
	},
	searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
		console.log('[searchSymbols]: Method call');
		const newSymbols = symbolAllList.map(symbol => ({
			symbol: symbol.getSymbol(),
			full_name: symbol.getName(),
			description: symbol.getSymboldescription(),
			exchange: '거래가능',
		}));

		onResultReadyCallback(newSymbols);
	},
	resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) => {
		console.log('[resolveSymbol]: Method start');
		const symbolItem = symbolAllList.find(symbolData => symbolData.getName() === symbolName || symbolData.getSymbol() === symbolName);
		if (!symbolItem) {
			console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
			onResolveErrorCallback('Cannot resolve symbol');
			return;
		}
		let time = '0800F-0700:234567';
		if (symbolItem.getSymbol().indexOf('101') > -1)
			time = '0845-1545:23456';
		else if (symbolItem.getSymbol().indexOf('HSI') > -1)
			time = '1015-1300,1400-1730,1815F-0400:234567';

		const tickValue = calculateTickPrice(symbolItem.getTicks(), symbolItem.getMark());
		console.log('틱사이즈', tickValue);
		const symbolInfo = {
			ticker: symbolItem.getSymbol(),
			name: symbolItem.getName(),
			description: symbolItem.getSymboldescription(),
			//expired: false,
			//variable_tick_size : 0.05,
			type: 'futures',
			//delay: 60,
			has_empty_bars: false,
			has_intraday: true,
			exchange: '선물',
			minmov: symbolItem.getTicks(),
			timezone: 'Asia/Seoul',
			//volume_precision: 2,
			pointValue: 0.0001,
			pricescale: Math.pow(10, symbolItem.getMark()),
			//has_intraday: false,
			visible_plots_set: 'ohlc',
			has_weekly_and_monthly: false,
			supported_resolutions: configurationData.supported_resolutions,
			volume_precision: 2,
			data_status: 'streaming',
			has_daily: false,
			intraday_multipliers: ['1'],
			//session_holidays:["20231225","20240101"],
			session: time
		};
		localStorage.setItem('lastViewedSymbol', symbolName);
		setTimeout(() => { onSymbolResolvedCallback(symbolInfo); }, 4);
		console.log('[resolveSymbol]: Method call', symbolName);

	},
	getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
		console.log('[getMarks]: Method call');
	},
	getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
		let { from, to, firstDataRequest, countBack } = periodParams;
		let candlesticks = [];
		const symbolCode = symbolInfo.full_name + '#' + resolution;
		let allBars = []; // 모든 바를 저장할 배열
		let noDataDays = 0; // 데이터가 없는 날의 수
		let totalRequests = 0; // 총 요청 횟수
		let receivedCount = 0; // 요청받은 데이터 수 추적
		console.log('getBars 요청시작 :', Date(from * 1000), '요청종료 :', Date(to * 1000), '요청사이즈 :', countBack);

		while (true) {
			try {
				const requestCount = Math.max(countBack - allBars.length - receivedCount, 0); // 남은 요청 수 계산
				if (requestCount <= 0 || totalRequests >= 30) break; // 요청할 데이터가 없거나 최대 요청 횟수 초과 시 종료

				const date1 = new Date(from * 1000 * requestCount);
				const date2 = new Date(to * 1000);
				candlesticks = await GetCandlesticksHistory(symbolInfo.ticker, from, to, resolution, requestCount);
				receivedCount += candlesticks.length; // 요청받은 데이터 수 증가

				console.log('요청시작 :', date1, '요청종료 :', date2, '요청사이즈 :(', requestCount, ' / ', candlesticks.length, ')', '총 갯수 : ', receivedCount);


				if (candlesticks == null || candlesticks.length === 0) {
					// 데이터가 없으면 noDataDays 증가
					noDataDays++;
					console.log('시간 변경하면서 재요청.: ', noDataDays);
					if (noDataDays > 30) {
						// 최대 30일 동안 데이터가 없으면 종료
						console.log('최대 30일 동안 데이터가 없으면 종료');
						break;
					}
				} else {
					// 데이터가 있으면 noDataDays 초기화
					noDataDays = 0;

					// Data is available, convert and add it to allBars
					let bars = [];
					candlesticks.forEach(candlestick => {
						const time = candlestick.getTime() * 1000 - 60 * 1000; // 1분(60초)을 빼기
						if (time >= from * 1000 && time < to * 1000) {
							bars.push({
								time: time,
								open: candlestick.getOpen(),
								high: candlestick.getHigh(),
								low: candlestick.getLow(),
								close: candlestick.getClose(),
								volume: candlestick.getVolume(),
							});
						}
					});

					// 시간 순서대로 정렬
					bars.sort((a, b) => a.time - b.time);
					allBars = allBars.concat(bars);

					if (allBars.length >= countBack) {
						// 필요한 데이터 개수를 충족하면 종료
						break;
					}
				}

				// 다음 요청을 위해 from과 to를 조정
				to = from;
				from -= countBack * 60; // from을 countBack 분 전으로 조정
				totalRequests++; // 요청 횟수 증가

			} catch (error) {
				console.log('[getBars]: Get error', error);
				onErrorCallback(error);
				return;
			}
		}

		if (firstDataRequest && allBars.length > 0) {
			lastBarsCache.set(symbolCode, { ...allBars[allBars.length - 1] });
		}

		if (allBars.length > 0) {
			onHistoryCallback(allBars, { noData: false });
		} else {
			onHistoryCallback([], { noData: true });
		}
	},
	subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
		console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);

		const request = new proto.chart.SubscribeRequest();
		request.setSymbol(subscriberUID);
		request.setClientid(myUUID);
		console.log(resolution);

		const symbolCode = `${symbolInfo.full_name}#${resolution}`;
		const stream = client.getSubscribe(request, { 'custom-header-1': 'value1' });

		stream.on('status', (status) => {
			if (status.code == 0) {
				console.log('status: 정상종료', status, getCurrentDateISO());
			} else {
				console.error('status: 비정상 종료', status, getCurrentDateISO());
			}
		});

		stream.on('data', (response) => {
			if (subscriberUID != response.getSymbol()) {
				console.log('다른 종목의 데이터: ', response.getSymbol());
				return;
			}

			const time = response.getTime() * 1000 - 60 * 1000; // 1분(60초)을 빼기
			const price = response.getPrice();
			const volume = response.getVolume();
			const lastDailyBar = lastBarsCache.get(symbolCode);

			let bar;
			if (!lastDailyBar || lastDailyBar.time < time) {
				// 마지막 바가 없거나 마지막 바의 시간이 현재 시간보다 작은 경우, 새로운 바 생성
				bar = {
					time: time, // 현재 시간을 새 바의 시간으로 설정
					open: price,
					high: price,
					low: price,
					close: price,
					volume: volume,
				};
			} else {
				// 마지막 바의 시간이 현재 시간과 동일한 경우, 기존 바 업데이트
				bar = {
					...lastDailyBar,
					high: Math.max(lastDailyBar.high, price),
					low: Math.min(lastDailyBar.low, price),
					close: price,
					volume: lastDailyBar.volume + volume,
				};
			}

			lastBarsCache.set(symbolCode, bar);
			onRealtimeCallback(bar);
		});

		stream.on('error', (err) => {
			console.error(err, getCurrentDateISO());
		});

		stream.on('end', () => {
			console.log('Stream ended', getCurrentDateISO());
		});
	},
	unsubscribeBars: (subscriberUID) => {
		console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);


		const request1 = new proto.chart.UnsubscribeRequest();
		var metadata = { 'custom-header-1': 'value1' };
		request1.setSymbol(subscriberUID);
		request1.setClientid(myUUID);

		return new Promise((resolve, reject) => {

			client.getUnsubscribe(request1, metadata, (err, response) => {
				if (err) {
					console.error(err);
					reject(err);
				} else {
					const message = response.getMessage();
					resolve(message);
				}
			});
		});

	},
};

async function GetCandlesticksHistory(symbol, from_time, to_time, interval, requestCount) {
	const request1 = new proto.chart.CandlestickRequest();
	var metadata = { 'custom-header-1': 'value1' };

	request1.setSymbol(symbol);
	request1.setTotime(to_time);
	request1.setFromtime(from_time);
	if (interval == '1D')
		interval = '1440';

	request1.setInterval(interval);

	return new Promise((resolve, reject) => {

		client.getCandlestickData(request1, metadata, (err, response) => {
			if (err) {
				console.error(err);
				reject(err);
			} else {
				const candlesticks = response.getCandlesticksList();
				console.log('요청완료 사이즈 :', candlesticks.length);
				resolve(candlesticks);
			}
		});
	});
}
// 날짜 객체 생성 로직을 함수로 분리
function getCurrentDateISO() {
	return new Date(Date.now()).toISOString();
}

// 거래 가능 시간을 파싱하는 함수
function parseTradingSessions(session) {
	// '0845-1546:23456' 형식의 문자열을 파싱하여 시작 시간과 종료 시간을 배열로 반환
	return session.split(',').map(part => {
		const [timeRange] = part.split(':'); // 시간대만 분리 ('0845-1546')
		const [start, end] = timeRange.split('-').map(time => parseInt(time, 10)); // 시작 시간과 종료 시간 분리 및 정수 변환
		return { start, end };
	});
}

// 주어진 시간이 거래 가능 시간대에 속하는지 확인하는 함수
function isInTradingSession(time, sessions) {
	const hourMin = time.getHours() * 100 + time.getMinutes(); // 'HHMM' 형식으로 변환
	return sessions.some(session => hourMin >= session.start && hourMin <= session.end);
}

// 주말인지 확인하는 함수
function isWeekend(date) {
	const dayOfWeek = date.getDay();
	return dayOfWeek === 0 || dayOfWeek === 6; // 0: 일요일, 6: 토요일
}