// Datafeed implementation

import Datafeed from './datafeed.js';
import { symbolAllList } from './streaming.js';

export async function initializeTradingView() {

    const symbol = symbolAllList[0].getSymbol();
    console.log(symbol);
    if (symbolAllList.length > 0 && symbolAllList[0]) {
        const symbol = symbolAllList[0].getSymbol();
        console.log('심볼:', symbol);
    } else {
        console.log('심볼 리스트가 비어있습니다.');
    }

    var lastViewedSymbol = globalSymbol ? globalSymbol : localStorage.getItem('lastViewedSymbol');
    if (!lastViewedSymbol) {
        localStorage.setItem('lastViewedSymbol', symbolAllList[0].getSymbol());
        lastViewedSymbol = symbolAllList[0].getSymbol();
    }


    window.tvWidget = new TradingView.widget({
        symbol: lastViewedSymbol, // Default symbol
        interval: '1',                // Default interval
        fullscreen: true,             // Displays the chart in the fullscreen mode
        container: 'tv_chart_container',  // Reference to an attribute of the DOM element
        datafeed: Datafeed,
        locale: 'ko',
        timezone: 'Asia/Seoul',
        autosize: true,
        timeframe: '2H',
        library_path: '../charting_library/',
        enabled_features: ["show_spread_operators", "always_show_legend_values_on_mobiles"],

        disabled_features: ["items_favoriting", "show_object_tree", "header_compare", "header_quick_search", "left_toolbar"],
        studies_overrides: {
            'MACD.histogram.color.0': '#FF0000',
            'MACD.histogram.color.1': '#FF9B9B',
            'MACD.histogram.color.2': '#0000FF',
            'MACD.histogram.color.3': '#9393FF',
            'volume.volume.color.0': '#0000FF', // 그로잉 색상 설정 (빨간색)
            'volume.volume.color.1': '#FF0000', // 폴링 색상 설정 (파란색)
            //'Volum.volume.borderColor': '#FF0000', // 테두리 색상 설정
            //'Volum.smoothed.ma.transparency': 100 // 투명도 설정 (0-100)
        }

    });
    window.tvWidget.onChartReady(() => {
        const chart = window.tvWidget.activeChart();
        chart.createStudy('Volume', false, true, { in_0: 14, in_1: 30, in_3: 'close', in_2: 9 });
        // MACD 지표 추가
        window.tvWidget.activeChart().createStudy('MACD', false, false, { in_0: 14, in_1: 30, in_3: 'close', in_2: 9 });
        window.tvWidget.activeChart().createStudy('Moving Average Triple', false, false, { length: 26 },
            {
                'showLabelsOnPriceScale': false,
            });
        window.tvWidget.activeChart().createStudy('Bollinger Bands', false, false, { length: 20, deviations: 2 },
            {
                'showLabelsOnPriceScale': false,
            });
        window.tvWidget.chart().getSeries().setChartStyleProperties(1, {
            upColor: '#FF0000',
            downColor: '#0000FF',
            borderUpColor: '#FF0000',
            borderDownColor: '#0000FF',
            wickUpColor: '#FF0000',
            wickDownColor: '#0000FF',

        });

    });

}


document.addEventListener('DOMContentLoaded', function () {

});

