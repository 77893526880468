import { initializeTradingView } from './main.js';
import { CandlestickServiceClient } from '../proto/ChartService_grpc_web_pb';

const client = new CandlestickServiceClient('https://chart.mts-today.com:8443');
var symbolAllList=[];
// client를 export
export { client, symbolAllList };



console.log('[streaming]: Method 요청');
var request = new proto.chart.SymbolRequest();
var metadata = { 'custom-header-1': 'value1' };
client.getSymbols(request, metadata, (err, response) => {
	symbolAllList = new Array();
    console.log('[getSymbols]: Method 요청');
    if (err) {
        console.log(err);
        console.log(err.message);
        return;
    }
    else {
        symbolAllList = response.getSymbolsList();
		initializeTradingView();
    }
});